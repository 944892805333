import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[fp-email-logs-header]',
  templateUrl: './email-logs-header.component.html',
  styleUrls: ['../email-logs.component.scss'],
})
export class EmailLogsHeaderComponent {
  @Input() returnAll: Boolean;
}
