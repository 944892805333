import { Action } from '@ngrx/store';
import { StatusLead } from 'src/app/shared/types/status-lead.types';

export const PRE_LEAD_STATUSES_LOAD = '[PRE_STATUSES] Load statuses';
export const PRE_LEAD_STATUSES_LOAD_SUCCESS = '[PRE_STATUSES] Load statuses successful';
export const PRE_LEAD_STATUSES_LOAD_ERROR = '[PRE_STATUSES] Load statuses failed';

export class PreLeadStatusesLoad implements Action {
  readonly type = PRE_LEAD_STATUSES_LOAD;
}

export class PreLeadStatusesLoadSuccess implements Action {
  readonly type = PRE_LEAD_STATUSES_LOAD_SUCCESS;
  constructor(public statuses: StatusLead[]) {}
}

export class PreLeadStatusesLoadError implements Action {
  readonly type = PRE_LEAD_STATUSES_LOAD_ERROR;
}

export const POST_LEAD_STATUSES_LOAD = '[POST_STATUSES] Load statuses';
export const POST_LEAD_STATUSES_LOAD_SUCCESS = '[POST_STATUSES] Load statuses successful';
export const POST_LEAD_STATUSES_LOAD_ERROR = '[POST_STATUSES] Load statuses failed';

export class PostLeadStatusesLoad implements Action {
  readonly type = POST_LEAD_STATUSES_LOAD;
}

export class PostLeadStatusesLoadSuccess implements Action {
  readonly type = POST_LEAD_STATUSES_LOAD_SUCCESS;
  constructor(public statuses: StatusLead[]) {}
}

export class PostLeadStatusesLoadError implements Action {
  readonly type = POST_LEAD_STATUSES_LOAD_ERROR;
}

export const DECISION_LEAD_STATUSES_LOAD = '[DECISION_STATUSES] Load statuses';
export const DECISION_LEAD_STATUSES_LOAD_SUCCESS = '[DECISION_STATUSES] Load statuses successful';
export const DECISION_LEAD_STATUSES_LOAD_ERROR = '[DECISION_STATUSES] Load statuses failed';

export class DecisionLeadStatusesLoad implements Action {
  readonly type = DECISION_LEAD_STATUSES_LOAD;
}

export class DecisionLeadStatusesLoadSuccess implements Action {
  readonly type = DECISION_LEAD_STATUSES_LOAD_SUCCESS;
  constructor(public statuses: StatusLead[]) {}
}

export class DecisionLeadStatusesLoadError implements Action {
  readonly type = DECISION_LEAD_STATUSES_LOAD_ERROR;
}

export type PreActions = PreLeadStatusesLoad | PreLeadStatusesLoadSuccess | PreLeadStatusesLoadError;

export type PostActions = PostLeadStatusesLoad | PostLeadStatusesLoadSuccess | PostLeadStatusesLoadError;

export type DecisionActions =
  | DecisionLeadStatusesLoad
  | DecisionLeadStatusesLoadSuccess
  | DecisionLeadStatusesLoadError;
