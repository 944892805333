<th style="width: 130px" pResizableColumn pSortableColumn="created_at">
  Created At
  <p-sortIcon field="created_at"></p-sortIcon>
</th>
<th pResizableColumn pSortableColumn="recipient">
  Recipient
  <p-sortIcon field="recipient"></p-sortIcon>
</th>
<th style="width: 240px" pResizableColumn pSortableColumn="email_type">
  Type
  <p-sortIcon field="email_type"></p-sortIcon>
</th>
<th style="width: 190px" pResizableColumn pSortableColumn="when">
  Dispatch on
  <p-sortIcon field="when"></p-sortIcon>
</th>
<th *ngIf="!returnAll" style="width: 115px" pResizableColumn pSortableColumn="sent_at">
  Sent
  <p-sortIcon field="sent_at"></p-sortIcon>
</th>
<th style="width: 90px" pResizableColumn>Render</th>
<th style="width: 115px" pResizableColumn>Don't Send</th>
<th style="width: 110px" pResizableColumn>Send Now</th>
<th *ngIf="!returnAll" style="width: 90px" pResizableColumn pSortableColumn="read_at">
  Viewed
  <p-sortIcon field="read_at"></p-sortIcon>
</th>
