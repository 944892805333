<p-dialog
  [breakpoints]="{ '960px': '85vw', '640px': '100vw' }"
  [style]="{ width: '80vw', height: '100%', opacity: 1 }"
  [(visible)]="display"
  dismissableMask="true"
  [maximizable]="true"
  header="Rendered Email"
>
  <div style="position: relative; height: 100%">
    <p-progressBar
      *ngIf="loading"
      mode="indeterminate"
      [style]="{ height: '3px', position: 'absolute', width: '100%' }"
    ></p-progressBar>
    <iframe (load)="stopLoading()" id="frame" [src]="emailURL" scrolling="yes"></iframe>
  </div>
</p-dialog>

<span
  (click)="displayEmail()"
  pButton
  pRipple
  style="text-decoration: none"
  type="button"
  icon="pi pi-search"
  class="p-button-rounded"
></span>
