<ng-container *ngIf="showCard; then withCard; else noCard"> </ng-container>

<ng-template #withCard>
  <p-card [header]="title">
    <ng-container *ngTemplateOutlet="noCard"> </ng-container>
  </p-card>
</ng-template>

<ng-template #noCard>
  <ng-container *ngIf="answers">
    <div class="mt-1" *ngFor="let answer of answers; trackBy: trackAnswer">
      <p-panel [header]="answer.question.title" [toggleable]="false" [collapsed]="false" [toggler]="'header'">
        <ng-template pTemplate="icons">
          <ng-container *ngIf="answer.question.short_title && answer.question.short_title !== 'skills'">
            <fp-comment [submissionId]="answer.submission" [field]="'other_answers_' + answer.question.short_title"></fp-comment>
          </ng-container>
          <ng-container *ngIf="!answer.question.short_title || answer.question.short_title === 'skills'">
            <fp-comment [submissionId]="answer.submission" [field]="'other_answers_' + answer.question.title"></fp-comment>
          </ng-container>
        </ng-template>
        <div class="flex">
          <span
            class="textarea"
            role="textbox"
            contenteditable
            [id]="'input_' + answer.id"
            class="answer-input mr-2"
            (keyup)="utilsService.changedValue($event, answer)"
            >{{ answer.value }}</span
          >
          <button class="ml-auto change-button" [id]="'button_' + answer.id" (click)="utilsService.changeValueOfAnswer(answer)">
            <i class="pi pi-save"></i>
          </button>
        </div>
      </p-panel>
    </div>
  </ng-container>
</ng-template>
