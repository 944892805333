import { createSelector } from '@ngrx/store';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { AppState } from '../reducers';

export const selectConversations = (state: AppState) => state.conversations;

export const selectFieldConversation = createSelector(selectConversations, (allConversations: Conversation[], props) => {
  if (allConversations) {
    return allConversations.filter(
      (conversation: Conversation) => conversation.field === props.field && conversation.resolved === false
    )[0];
  }
});
