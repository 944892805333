import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'fp-render-email',
  templateUrl: './render-email.component.html',
  styleUrls: ['./render-email.component.scss'],
})
export class RenderEmailComponent implements OnChanges {
  @Input() emailLogId: string;
  environment = environment;
  display = false;
  loading = true;
  emailURL: SafeResourceUrl;
  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private notificationService: NotificationService) {}

  async ngOnChanges() {
    this.emailURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + '/email_log/render_email/' + this.emailLogId + '/');
  }
  displayEmail() {
    let url_string = environment.apiUrl + '/email_log/render_email/' + this.emailLogId + '/';
    this.http.get<string>(url_string).subscribe({
      next: res => {
        this.emailURL = this.sanitizer.bypassSecurityTrustResourceUrl(url_string);
        this.display = true;
      },
      error: err => {
        if (err.status == 200) {
          this.emailURL = this.sanitizer.bypassSecurityTrustResourceUrl(url_string);
          this.display = true;
        } else if (err.error.includes('Wrongly configured variable') || err.status == 403) {
          this.notificationService.openSnackBar('error', err.error);
        }
      },
    });
  }
  stopLoading() {
    this.loading = false;
  }
}
