import { Action } from '@ngrx/store';
import { Coach } from 'src/app/shared/types/coach.types';

export const COACH_SET_INITIAL = '[COACH] Set initial';
export const COACH_LOAD = '[COACH] Load coach';
export const COACH_LOAD_SUCCESS = '[COACH] Load coach successful';
export const COACH_LOAD_ERROR = '[COACH] Load coach failed';
export const UPDATE_COACH = '[COACH] Update coach';
export const UPDATE_COACH_SUCCESS = '[COACH] Update coach successful';
export const UPDATE_COACH_ERROR = '[COACH] Update coach failed';

export class CoachSetInitial implements Action {
  readonly type = COACH_SET_INITIAL;
}

export class CoachLoad implements Action {
  readonly type = COACH_LOAD;
  constructor(public id: string) {}
}

export class CoachLoadSuccess implements Action {
  readonly type = COACH_LOAD_SUCCESS;
  constructor(public payload: Coach) {}
}

export class CoachLoadError implements Action {
  readonly type = COACH_LOAD_ERROR;
}

export class UpdateCoach implements Action {
  readonly type = UPDATE_COACH;
  constructor(public submissionId: string, public coach: Partial<Coach>) {}
}

export class UpdateCoachSuccess implements Action {
  readonly type = UPDATE_COACH_SUCCESS;
  constructor(public coach: Coach) {}
}

export class UpdateCoachError implements Action {
  readonly type = UPDATE_COACH_ERROR;
  constructor(public id: string) {}
}

export type Actions = CoachSetInitial | CoachLoad | CoachLoadSuccess | CoachLoadError | UpdateCoach | UpdateCoachSuccess | UpdateCoachError;
