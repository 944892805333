import { Component, Input, TrackByFunction } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Answer } from 'src/app/shared/types/answer.types';

@Component({
  selector: 'fp-other-answers',
  templateUrl: './other-answers.component.html',
  styleUrls: ['./other-answers.component.scss'],
})
export class OtherAnswersComponent {
  constructor(public utilsService: UtilsService) {}

  @Input() answers: Answer[];
  @Input() title: string = 'Other answers';
  @Input() showCard: boolean = true;
  @Input() submissionId: string;
  trackAnswer: TrackByFunction<Answer> = (i, item) => item.id;
}
