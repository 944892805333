import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { EmailLog } from 'src/app/shared/types/email-log.types';
import { RequireOne } from 'src/app/shared/utils';
import { EmailLogService } from '../../../services/email-log.service';
import { UtilsService } from '../../../services/utils.service';
import { AppState } from 'src/app/core/reducers';
import { UpdateEmailLog } from 'src/app/core/actions/email-log.actions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[fp-email-logs-body]',
  templateUrl: './email-logs-body.component.html',
  styleUrls: ['./email-logs-body.component.scss'],
})
export class EmailLogsBodyComponent {
  @Input() emailLog: EmailLog;
  @Output() emailLogChange = new EventEmitter<{ emailLog: EmailLog; changeType: string }>();
  @Input() returnAll: Boolean = false;
  constructor(
    private store: Store<AppState>,
    public utilService: UtilsService,
    public emailLogService: EmailLogService,
    private confirmationService: ConfirmationService
  ) {}

  public setNewWhen(emailLog: EmailLog, val) {
    const newEmailLog: RequireOne<EmailLog, 'id'> = { id: emailLog.id, when: val };
    if (Date.parse(emailLog.when) !== Date.parse(newEmailLog.when)) {
      this.store.dispatch(new UpdateEmailLog(newEmailLog));
    }
  }

  confirmSend(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Send email now?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.emitChange('sentAt');
      },
      reject: () => {},
    });
  }

  confirmDontSend(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Prevent from sending this email?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.emitChange('dontSend');
      },
      reject: () => {},
    });
  }

  private emitChange(changeType: string) {
    this.emailLogChange.emit({ emailLog: this.emailLog, changeType: changeType });
  }
}
