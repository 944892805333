import { createAction, props } from '@ngrx/store';
import { Reminder } from 'src/app/shared/types/reminder.types';

export const ReminderSetInitial = createAction('[REMINDER] Set initial');
export const RemindersLoad = createAction('[REMINDER] Load reminder');
export const RemindersLoadSuccess = createAction('[REMINDER] Load reminder successful', props<{ reminders: Reminder[] }>());
export const RemindersLoadError = createAction('[REMINDER] Load reminder failed');

export const RemindersSubmissionLoad = createAction('[REMINDER] Load reminder submission', props<{ submissionId: string }>());
export const RemindersSubmissionLoadSuccess = createAction(
  '[REMINDER] Load reminder submission successful',
  props<{ reminders: Reminder[] }>()
);
export const RemindersSubmissionLoadError = createAction('[REMINDER] Load reminder submission failed');

export const ReminderCreate = createAction('[REMINDER] Create reminder', props<{ reminder: Partial<Reminder> }>());

export const ReminderCreateSuccess = createAction('[REMINDER] Create reminder successful', props<{ reminder: Reminder }>());

export const ReminderCreateError = createAction('[REMINDER] Create reminder failed', props<{ error: any }>());

export const ReminderUpdate = createAction('[REMINDER] Update reminder', props<{ reminderId: string; reminder: Partial<Reminder> }>());
export const ReminderUpdateSuccess = createAction('[REMINDER] Update reminder successful', props<{ reminder: Reminder }>());
export const ReminderUpdateError = createAction('[REMINDER] Update reminder failed');

export const ReminderArrayUpdate = createAction('[REMINDER] ReminderArrayUpdate', props<{ reminders: Reminder[] }>());
export const ReminderArrayUpdateSuccess = createAction('[REMINDER] ReminderArrayUpdate successful', props<{ reminders: Reminder[] }>());
export const ReminderArrayUpdateError = createAction('[REMINDER] ReminderArrayUpdate failed');

export type Actions =
  | ReturnType<typeof ReminderSetInitial>
  | ReturnType<typeof RemindersLoad>
  | ReturnType<typeof RemindersLoadSuccess>
  | ReturnType<typeof RemindersLoadError>
  | ReturnType<typeof RemindersSubmissionLoad>
  | ReturnType<typeof RemindersSubmissionLoadSuccess>
  | ReturnType<typeof RemindersSubmissionLoadError>
  | ReturnType<typeof ReminderUpdate>
  | ReturnType<typeof ReminderUpdateSuccess>
  | ReturnType<typeof ReminderUpdateError>
  | ReturnType<typeof ReminderCreate>
  | ReturnType<typeof ReminderCreateSuccess>
  | ReturnType<typeof ReminderCreateError>
  | ReturnType<typeof ReminderArrayUpdate>
  | ReturnType<typeof ReminderArrayUpdateSuccess>
  | ReturnType<typeof ReminderArrayUpdateError>;
