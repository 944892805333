<td
  [pTooltip]="emailLog.created_at | date : 'yyyy-MM-dd HH:mm'"
  tooltipPosition="left"
  showDelay="300"
  style="text-align: center; cursor: default"
>
  {{ emailLog.created_at | date : 'yyyy-MM-dd' }}
</td>
<td style="white-space: pre-wrap">{{ emailLog?.recipient.join('\n') }}</td>
<td>{{ emailLog.email_type | removeUnderscore | titlecase }}</td>
<td style="text-align: center">
  <p-calendar
    #cal
    [disabled]="emailLog.dont_send || emailLog.sent_at"
    [readonlyInput]="true"
    [ngModel]="emailLog.when | date : 'yyyy-MM-dd HH:mm'"
    appendTo="body"
    dateFormat="yy-mm-dd"
    [showTime]="true"
    (onClose)="setNewWhen(emailLog, cal.value)"
    [inputStyle]="{ cursor: emailLog.dont_send || emailLog.sent_at ? 'default' : 'pointer' }"
  ></p-calendar>
</td>
<td *ngIf="!returnAll" style="text-align: center">
  <ng-container *ngIf="emailLog.sent_at">
    <span class="p-tag p-tag-success">Sent</span>
  </ng-container>
  <ng-container *ngIf="!emailLog.sent_at">
    <span class="p-tag p-tag-warning">Not sent</span>
  </ng-container>
</td>
<td style="text-align: center">
  <fp-render-email [emailLogId]="emailLog.id"></fp-render-email>
</td>
<td style="text-align: center">
  <button
    (click)="confirmDontSend($event)"
    [disabled]="emailLog.sent_at"
    pButton
    pRipple
    type="button"
    icon="pi pi-minus-circle"
    class="p-button-rounded p-button-warning"
  ></button>
</td>
<td style="text-align: center">
  <button
    [disabled]="emailLog.sent_at || emailLog.dont_send"
    (click)="confirmSend($event)"
    pButton
    pRipple
    type="button"
    icon="pi pi-plus-circle"
    class="p-button-rounded"
  ></button>
</td>
<td *ngIf="!returnAll" style="text-align: center">
  <ng-container *ngIf="emailLog.read_at">
    <span [pTooltip]="emailLog.read_at | date : 'yyyy-MM-dd HH:mm'">{{ emailLog.read_at | date : 'yyyy-MM-dd' }} </span>
  </ng-container>
  <ng-container *ngIf="!emailLog.read_at">
    <span class="pi pi-eye-slash"></span>
  </ng-container>
</td>
