import { Action } from '@ngrx/store';
import { Comment } from 'src/app/shared/types/comment.types';
import { Conversation } from 'src/app/shared/types/conversation.types';

export const CONVERSATION_SET_INITIAL = '[CONVERSATION] Set initial';
export const CONVERSATION_LOAD = '[CONVERSATION] Load conversation';
export const CONVERSATION_LOAD_SUCCESS = '[CONVERSATION] Load conversation successful';
export const CONVERSATION_LOAD_ERROR = '[CONVERSATION] Load conversation failed';
export const USER_CONVERSATION_LOAD = '[CONVERSATION] Load user conversation';
export const USER_CONVERSATION_LOAD_ERROR = '[CONVERSATION] Load user conversation failed';
export const ADD_COMMENT_TO_CONVERSATION = '[CONVERSATION] Add comment to conversation';
export const ADD_COMMENT_TO_CONVERSATION_SUCCESS = '[CONVERSATION] Add comment to conversation successful';
export const ADD_COMMENT_TO_CONVERSATION_ERROR = '[CONVERSATION] Add comment to conversation failed';
export const RESOLVE_CONVERSATION = '[CONVERSATION] Resolving conversation';
export const RESOLVE_CONVERSATION_SUCCESS = '[CONVERSATION] Resolving conversation successful';
export const RESOLVE_CONVERSATION_ERROR = '[CONVERSATION] Resolving conversation failed';
export const REMOVE_USER_FROM_CONVERSATION = '[CONVERSATION] Remove user from conversation';
export const REMOVE_USER_FROM_CONVERSATION_SUCCESS = '[CONVERSATION] Remove user from conversation successful';
export const REMOVE_USER_FROM_CONVERSATION_ERROR = '[CONVERSATION] Remove user from conversation failed';

export class ConversationSetInitial implements Action {
  readonly type = CONVERSATION_SET_INITIAL;
}

export class ConversationLoad implements Action {
  readonly type = CONVERSATION_LOAD;
  constructor(public id: string) {}
}

export class ConversationLoadSuccess implements Action {
  readonly type = CONVERSATION_LOAD_SUCCESS;
  constructor(public payload: Conversation[]) {}
}

export class ConversationLoadError implements Action {
  readonly type = CONVERSATION_LOAD_ERROR;
}

export class UserConversationLoad implements Action {
  readonly type = USER_CONVERSATION_LOAD;
}

export class UserConversationLoadError implements Action {
  readonly type = USER_CONVERSATION_LOAD_ERROR;
}

export class AddCommentToConversation implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION;
  constructor(public conversation: Partial<Conversation>, public comment: Comment) {}
}

export class AddCommentToConversationSuccess implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation, public slackSuccess: boolean) {}
}

export class AddCommentToConversationError implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION_ERROR;
  constructor(public id: string) {}
}

export class ResolveConversation implements Action {
  readonly type = RESOLVE_CONVERSATION;
  constructor(public conversation: Partial<Conversation>) {}
}

export class ResolveConversationSuccess implements Action {
  readonly type = RESOLVE_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation) {}
}

export class ResolveConversationError implements Action {
  readonly type = RESOLVE_CONVERSATION_ERROR;
}

export class RemoveUserFromConversation implements Action {
  readonly type = REMOVE_USER_FROM_CONVERSATION;
  constructor(public conversation: Partial<Conversation>, public userId: string) {}
}

export class RemoveUserFromConversationSuccess implements Action {
  readonly type = REMOVE_USER_FROM_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation) {}
}

export class RemoveUserFromConversationError implements Action {
  readonly type = REMOVE_USER_FROM_CONVERSATION_ERROR;
}

export type Actions =
  | ConversationSetInitial
  | ConversationLoad
  | ConversationLoadSuccess
  | ConversationLoadError
  | UserConversationLoad
  | UserConversationLoadError
  | AddCommentToConversation
  | AddCommentToConversationSuccess
  | AddCommentToConversationError
  | ResolveConversation
  | ResolveConversationSuccess
  | ResolveConversationError
  | RemoveUserFromConversation
  | RemoveUserFromConversationSuccess
  | RemoveUserFromConversationError;
