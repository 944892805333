import { Action } from '@ngrx/store';
import { LazyLoadEvent } from 'primeng/api';
import { EmailLog } from 'src/app/shared/types/email-log.types';
import { RequireOne } from 'src/app/shared/utils';

export const EMAILLOG_SET_INITIAL = '[EMAILLOG] Set initial';
export const EMAILLOG_LOAD = '[EMAILLOG] Load emaillog';
export const EMAILLOG_LOAD_SUCCESS = '[EMAILLOG] Load emaillog successful';
export const EMAILLOG_LOAD_ERROR = '[EMAILLOG] Load emaillog failed';
export const UPDATE_EMAILLOG = '[EMAILLOG] Update emaillog';
export const UPDATE_EMAILLOG_SUCCESS = '[EMAILLOG] Update emaillog successful';
export const UPDATE_EMAILLOG_ERROR = '[EMAILLOG] Update emaillog failed';
export const EMAILLOG_SEND = '[EMAILLOG_SEND] Send email';
export const EMAILLOG_SEND_SUCCESS = '[EMAILLOG_SEND] Send email successful';
export const EMAILLOG_SEND_ERROR = '[EMAILLOG_SEND] Send email failed';

export class EmailLogSetInitial implements Action {
  readonly type = EMAILLOG_SET_INITIAL;
}

export class EmailLogLoad implements Action {
  readonly type = EMAILLOG_LOAD;
  constructor(public submissionId: string, public returnAll?: boolean, public event?: LazyLoadEvent) {}
}

export class EmailLogLoadSuccess implements Action {
  readonly type = EMAILLOG_LOAD_SUCCESS;
  constructor(public payload: EmailLog[]) {}
}

export class EmailLogLoadError implements Action {
  readonly type = EMAILLOG_LOAD_ERROR;
}

export class UpdateEmailLog implements Action {
  readonly type = UPDATE_EMAILLOG;
  constructor(public emaillog: RequireOne<EmailLog, 'id'>) {}
}

export class UpdateEmailLogSuccess implements Action {
  readonly type = UPDATE_EMAILLOG_SUCCESS;
  constructor(public payload: EmailLog) {}
}

export class UpdateEmailLogError implements Action {
  readonly type = UPDATE_EMAILLOG_ERROR;
  constructor(public id: string) {}
}

export class EmailLogSend implements Action {
  readonly type = EMAILLOG_SEND;
  constructor(public emailLog: EmailLog) {}
}

export class EmailLogSendSuccess implements Action {
  readonly type = EMAILLOG_SEND_SUCCESS;
  constructor(public emailLog: EmailLog) {}
}

export class EmailLogSendError implements Action {
  readonly type = EMAILLOG_SEND_ERROR;
}

export type Actions =
  | EmailLogSetInitial
  | EmailLogLoad
  | EmailLogLoadSuccess
  | EmailLogLoadError
  | UpdateEmailLog
  | UpdateEmailLogSuccess
  | UpdateEmailLogError
  | EmailLogSend
  | EmailLogSendSuccess
  | EmailLogSendError;
