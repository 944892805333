import { Action } from '@ngrx/store';
import { Status } from 'src/app/shared/types/status.types';

export const STATUSES_LOAD = '[STATUSES] Load statuses';
export const STATUSES_LOAD_SUCCESS = '[STATUSES] Load statuses successful';
export const STATUSES_LOAD_ERROR = '[STATUSES] Load statuses failed';

export class StatusesLoad implements Action {
  readonly type = STATUSES_LOAD;
}

export class StatusesLoadSuccess implements Action {
  readonly type = STATUSES_LOAD_SUCCESS;
  constructor(public payload: Status[]) {}
}

export class StatusesLoadError implements Action {
  readonly type = STATUSES_LOAD_ERROR;
}

export type Actions = StatusesLoad | StatusesLoadSuccess | StatusesLoadError;
