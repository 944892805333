import { Action } from '@ngrx/store';
import { MRStatus } from 'src/app/shared/types/mentee-relations.types';

export const MRSTATUSES_LOAD = '[MrSTATUSES] Load MrStatuses';
export const MRSTATUSES_LOAD_SUCCESS = '[MrSTATUSES] Load MrStatuses successful';
export const MRSTATUSES_LOAD_ERROR = '[MrSTATUSES] Load MrStatuses failed';

export class MrStatusesLoad implements Action {
  readonly type = MRSTATUSES_LOAD;
}

export class MrStatusesLoadSuccess implements Action {
  readonly type = MRSTATUSES_LOAD_SUCCESS;
  constructor(public payload: MRStatus[]) {}
}

export class MrStatusesLoadError implements Action {
  readonly type = MRSTATUSES_LOAD_ERROR;
}

export type Actions = MrStatusesLoad | MrStatusesLoadSuccess | MrStatusesLoadError;
