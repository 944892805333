import { Action } from '@ngrx/store';
import { Lead } from 'src/app/shared/types/lead.types';

export const LEAD_SET_INITIAL = '[LEAD] Set initial';
export const LEAD_LOAD = '[LEAD] Load LEAD';
export const LEAD_LOAD_SUCCESS = '[LEAD] Load LEAD successful';
export const LEAD_LOAD_ERROR = '[LEAD] Load LEAD failed';
export const UPDATE_LEAD = '[LEAD] Update LEAD';
export const UPDATE_LEAD_SUCCESS = '[LEAD] Update LEAD successful';
export const UPDATE_LEAD_ERROR = '[LEAD] Update LEAD failed';

export class LeadSetInitial implements Action {
  readonly type = LEAD_SET_INITIAL;
}

export class LeadLoad implements Action {
  readonly type = LEAD_LOAD;
  constructor(public id: string) {}
}

export class LeadLoadSuccess implements Action {
  readonly type = LEAD_LOAD_SUCCESS;
  constructor(public payload: Lead) {}
}

export class LeadLoadError implements Action {
  readonly type = LEAD_LOAD_ERROR;
}

export class UpdateLead implements Action {
  readonly type = UPDATE_LEAD;
  constructor(public leadId: string, public lead: Partial<Lead>) {}
}

export class UpdateLeadSuccess implements Action {
  readonly type = UPDATE_LEAD_SUCCESS;
  constructor(public lead: Lead) {}
}

export class UpdateLeadError implements Action {
  readonly type = UPDATE_LEAD_ERROR;
  constructor(public error: string) {}
}

export type Actions =
  | LeadSetInitial
  | LeadLoad
  | LeadLoadSuccess
  | LeadLoadError
  | UpdateLead
  | UpdateLeadSuccess
  | UpdateLeadError;
