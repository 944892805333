import { Action } from '@ngrx/store';
import { Answer } from 'src/app/shared/types/answer.types';

export const ANSWERS_SET_INITIAL = '[ANSWER] Set answers initial';
export const ANSWERS_LOAD = '[ANSWER] Load answers';
export const ANSWERS_LOAD_SUCCESS = '[ANSWER] Load answers successful';
export const ANSWERS_LOAD_ERROR = '[ANSWER] Load answers failed';

export const UPDATE_ANSWER = '[ANSWER] Update answer';
export const UPDATE_ANSWER_SUCCESS = '[ANSWER] Update answer success';
export const UPDATE_ANSWER_ERROR = '[ANSWER] Update answer failed';

export class AnswersSetInitial implements Action {
  readonly type = ANSWERS_SET_INITIAL;
}

export class AnswersLoad implements Action {
  readonly type = ANSWERS_LOAD;
  constructor(public submissionId: string) {}
}

export class AnswersLoadSuccess implements Action {
  readonly type = ANSWERS_LOAD_SUCCESS;
  constructor(public payload: Answer[]) {}
}

export class AnswersLoadError implements Action {
  readonly type = ANSWERS_LOAD_ERROR;
  constructor(public id: string) {}
}

export class UpdateAnswer implements Action {
  readonly type = UPDATE_ANSWER;
  constructor(public answerId: string, public value: string) {}
}

export class UpdateAnswerSuccess implements Action {
  readonly type = UPDATE_ANSWER_SUCCESS;
  constructor(public payload: Answer) {}
}

export class UpdateAnswerError implements Action {
  readonly type = UPDATE_ANSWER_ERROR;
  constructor(public id: string) {}
}

export type Actions =
  | AnswersSetInitial
  | AnswersLoad
  | AnswersLoadSuccess
  | AnswersLoadError
  | UpdateAnswer
  | UpdateAnswerSuccess
  | UpdateAnswerError;
