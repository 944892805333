import { Action } from '@ngrx/store';
import { Badge } from 'src/app/shared/types/badge.types';

export const BADGES_LOAD = '[BADGE] Load badges';
export const BADGES_LOAD_SUCCESS = '[BADGE] Load badges successful';
export const BADGES_LOAD_ERROR = '[BADGE] Load badges failed';

export class BadgesLoad implements Action {
  readonly type = BADGES_LOAD;
}

export class BadgesLoadSuccess implements Action {
  readonly type = BADGES_LOAD_SUCCESS;
  constructor(public payload: Badge[]) {}
}

export class BadgesLoadError implements Action {
  readonly type = BADGES_LOAD_ERROR;
}

export type Actions = BadgesLoad | BadgesLoadSuccess | BadgesLoadError;
