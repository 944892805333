<ng-container *ngIf="this.conversation?.comments?.length > 0">
  <p-badge (click)="toggle($event)" [value]="this.conversation?.comments.length" [severity]="'warning'" styleClass="mr-2"></p-badge>
</ng-container>
<ng-container *ngIf="this.conversation?.comments?.length === 0 || !this.conversation?.comments">
  <i class="ml-1 pi pi-comment" (click)="toggle($event)"></i>
</ng-container>

<p-overlayPanel [dismissable]="false" [showCloseIcon]="true" #panel [style]="{ width: '30%' }" [appendTo]="'body'">
  <ng-template pTemplate>
    <div class="flex justify-content-between align-items-center mb-3">
      <span class="created-at">{{ conversation?.created_at | date: 'yyyy-MM-dd' }}</span>
      <p-avatarGroup>
        <p-avatar
          (click)="removeNotifiedUser(user)"
          *ngFor="let user of conversation?.notified_users"
          class="avatar-group-item"
          [label]="user?.username?.slice(0, 2)"
          [pTooltip]="user?.username"
          shape="circle"
          [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }"
        ></p-avatar>
      </p-avatarGroup>
      <ng-container *ngIf="!resolved">
        <button
          tabindex="-1"
          pTooltip="Click to resolve"
          pButton
          pRipple
          type="button"
          icon="pi pi-lock-open"
          class="p-button-sm p-button-rounded p-button-outlined"
          (click)="confirmResolve(conversation, $event)"
        ></button>
      </ng-container>
      <ng-container *ngIf="resolved">
        <button
          tabindex="-1"
          pTooltip="Click to open issue"
          pButton
          pRipple
          type="button"
          icon="pi pi-lock"
          class="p-button-sm p-button-rounded p-button-outlined"
        ></button>
      </ng-container>
    </div>
    <!-- <p-divider *ngIf="this.conversation?.comments?.length > 0"></p-divider> -->
    <div
      *ngFor="let comment of conversation?.comments"
      class="mb-1 flex container"
      [ngClass]="{
        'right-comment': comment.created_by.username === meUser.username,
        'left-comment': comment.created_by.username !== meUser.username
      }"
    >
      <div
        [ngClass]="{
          'message-orange': comment.created_by.username === meUser.username,
          'message-blue': comment.created_by.username !== meUser.username
        }"
      >
        <p class="message-content">{{ comment.message }}</p>
        <div class="message-timestamp-left">{{ comment.created_by.username }}, {{ comment?.created_at | date: 'yyyy-MM-dd HH:mm' }}</div>
      </div>
    </div>

    <!-- <p-divider *ngIf="this.conversation?.comments?.length > 0"></p-divider> -->
    <div class="p-inputgroup mb-3" [style]="{ position: 'relative' }">
      <textarea
        #commentInput
        [rows]="3"
        autoResize="autoResize"
        type="text"
        pInputTextarea
        placeholder="Comment"
        (keydown)="suggestUser($event)"
        (keyup.enter)="addComment(commentInput.value)"
      ></textarea>
      <button
        tabindex="-1"
        (click)="addComment(commentInput.value)"
        type="button"
        pButton
        pRipple
        icon="pi pi-comment"
        styleClass="p-button-warn"
      ></button>
      <p-listbox
        id="userList"
        class="addUserDropdown"
        #addUserEl
        [options]="users"
        (onChange)="addUser($event)"
        optionLabel="username"
      ></p-listbox>
    </div>
  </ng-template>
</p-overlayPanel>
