import { Action } from '@ngrx/store';
import { PromoCode } from 'src/app/shared/types/promo-code.types';

export const PROMOCODES_LOAD = '[PROMOCODE] Load promocodes';
export const PROMOCODES_LOAD_SUCCESS = '[PROMOCODE] Load promocodes successful';
export const PROMOCODES_LOAD_ERROR = '[PROMOCODE] Load promocodes failed';

export class PromoCodesLoad implements Action {
  readonly type = PROMOCODES_LOAD;
}

export class PromoCodesLoadSuccess implements Action {
  readonly type = PROMOCODES_LOAD_SUCCESS;
  constructor(public payload: PromoCode[]) {}
}

export class PromoCodesLoadError implements Action {
  readonly type = PROMOCODES_LOAD_ERROR;
}

export type Actions = PromoCodesLoad | PromoCodesLoadSuccess | PromoCodesLoadError;
