import { Action } from '@ngrx/store';
import { FeedbackStatus } from 'src/app/shared/types/feedback-status.types';

export const FEEDBACKSTATUSES_LOAD = '[FEEDBACKSTATUS] Load feedbackstatuses';
export const FEEDBACKSTATUSES_LOAD_SUCCESS = '[FEEDBACKSTATUS] Load feedbackstatuses successful';
export const FEEDBACKSTATUSES_LOAD_ERROR = '[FEEDBACKSTATUS] Load feedbackstatuses failed';

export class FeedbackStatusesLoad implements Action {
  readonly type = FEEDBACKSTATUSES_LOAD;
}

export class FeedbackStatusesLoadSuccess implements Action {
  readonly type = FEEDBACKSTATUSES_LOAD_SUCCESS;
  constructor(public payload: FeedbackStatus[]) {}
}

export class FeedbackStatusesLoadError implements Action {
  readonly type = FEEDBACKSTATUSES_LOAD_ERROR;
}

export type Actions = FeedbackStatusesLoad | FeedbackStatusesLoadSuccess | FeedbackStatusesLoadError;
