import { UntypedFormGroup, Validators } from '@angular/forms';

// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function convertDate2String(date: Date, keepTime: boolean = false) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  if (keepTime) {
    return date.toISOString();
  } else {
    return date.toISOString().split('T')[0];
  }
}

export function convertDateTime2Date(date: string) {
  return date.slice(0, 10);
}

export function hasValue<Type>(value: Type): boolean {
  return value !== null && value !== undefined;
}

export function hasValueAndNotEmpty(value: any) {
  return value !== null && value !== undefined && value !== '';
}

export function isEmptyArray(value: any) {
  return Array.isArray(value) && value?.length == 0;
}

export function getLink(url: string) {
  if (hasValue(url)) {
    if (url.includes('://')) {
      return url;
    } else {
      return `http://` + url;
    }
  }
}

export const urlValidator = Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
export const linkedInValidator = Validators.pattern(/(linkedin)/);
export const certificateValidator = Validators.pattern(/^(https:\/\/drive.google.com\/file\/.*[^\/]+){1}/);

export type RequireOne<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & {
  [P in K]-?: T[P];
};

export function removeBasicHtml(html) {
  if (html) {
    return html
      .replace(/<[^>]+>/g, '')
      .replace('&nbsp;', '')
      .replace('&amp;', '')
      .replace('&gt;', '');
  } else {
    return html;
  }
}
