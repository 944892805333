import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { Listbox } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AddCommentToConversation, RemoveUserFromConversation, ResolveConversation } from 'src/app/core/actions/conversation.actions';
import { AppState } from 'src/app/core/reducers';
import { selectFieldConversation } from 'src/app/core/selectors/conversation.selector';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Comment } from 'src/app/shared/types/comment.types';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { User } from 'src/app/shared/types/user.types';
import { hasValue } from 'src/app/shared/utils';

@Component({
  selector: 'fp-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {
  @ViewChild('panel') panel: OverlayPanel;
  @ViewChild('addUserEl') addUserEl: Listbox;
  @ViewChild('commentInput') commentInput: ElementRef;
  constructor(
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService
  ) {}

  resolved = false;
  @Input() submissionId: string;
  @Input() field: string;

  conversation: Partial<Conversation>;
  conversation$: Subscription;
  meUser: User;
  users: User[];
  onShowObserver$: Subscription;

  async ngOnInit() {
    this.meUser = await this.store
      .select(state => state.settings.user)
      .pipe(filter(hasValue), first())
      .toPromise();

    this.users = await this.store
      .select(state => state.users)
      .pipe(filter(hasValue), first())
      .toPromise();

    this.users = this.users.filter(user => user.id !== this.meUser.id);

    this.conversation$ = this.store.select(selectFieldConversation, { field: this.field }).subscribe(data => {
      if (hasValue(data)) {
        this.conversation = cloneDeep(data);
      } else {
        this.conversation = {
          submission: this.submissionId,
          field: this.field,
          notified_users: [this.meUser],
        };
      }
    });
    this.onShowObserver$ = this.panel.onShow.subscribe(() => this.commentInput.nativeElement.focus());
  }

  toggle(event: Event) {
    this.panel.toggle(event);
  }

  toggleResolve(conversation: Partial<Conversation>) {
    if (this.conversation.id) {
      this.resolved = !this.resolved;
      this.store.dispatch(new ResolveConversation(conversation));
      setTimeout(() => this.panel.hide(), 300);
    } else {
      this.notificationService.openSnackBar('warn', "Hmmm... That's a bit weird to resolve issue that hasn't been created 🤔");
    }
  }

  confirmResolve(conversation: Partial<Conversation>, event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.toggleResolve(conversation);
      },
      reject: () => {
        //reject action
      },
    });
  }

  addComment(message: string) {
    if (message.length > 0 && this.addUserEl.el.nativeElement.style.display === 'none') {
      const comment: Comment = {
        message,
        created_by: this.meUser,
      };
      if (!this.conversation.submission) {
        this.conversation.submission = this.submissionId;
      }

      this.store.dispatch(new AddCommentToConversation(this.conversation, comment));
      this.commentInput.nativeElement.value = '';
    }
  }

  suggestUser(event: KeyboardEvent) {
    if (event.key === '@') {
      this.addUserEl.el.nativeElement.style.display = 'block';
    } else {
      this.addUserEl.el.nativeElement.style.display = 'none';
    }
  }

  addUser(event) {
    this.commentInput.nativeElement.value += event.value.username + ' ';
    this.conversation.notified_users.push(event.value);
    this.commentInput.nativeElement.focus();
    this.addUserEl.el.nativeElement.style.display = 'none';
  }

  removeNotifiedUser(user: User) {
    if (this.conversation.notified_users.length > 1) {
      if (this.conversation.id) {
        this.store.dispatch(new RemoveUserFromConversation(this.conversation, user.id));
      } else {
        this.conversation.notified_users.splice(this.conversation.notified_users.indexOf(user), 1);
      }
    } else {
      this.notificationService.openSnackBar('warn', 'Hmmm... Nice try, but there must be someone to be notified 🤭');
    }
  }

  ngOnDestroy() {
    if (this.conversation$) {
      this.conversation$.unsubscribe();
    }

    if (this.onShowObserver$) {
      this.onShowObserver$.unsubscribe();
    }
  }
}
