<p-confirmPopup></p-confirmPopup>

<div *ngIf="model === 'lead' || returnAll">
  <p-table
    #dt1
    [value]="emailLogs"
    [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="loadEmailLogs($event)"
    [rows]="30"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [globalFilterFields]="['recipient', 'email_type']"
    styleClass="p-datatable-gridlines"
    [resizableColumns]="true"
    [loading]="loading"
    responsiveLayout="scroll"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr fp-email-logs-header [returnAll]="returnAll"></tr>
    </ng-template>
    <ng-template pTemplate="body" let-emailLog>
      <tr
        fp-email-logs-body
        [ngClass]="{ 'email-disabled': emailLog.dont_send || emailLog.sent_at }"
        [emailLog]="emailLog"
        (emailLogChange)="onEmailLogChange($event)"
        [returnAll]="returnAll"
      ></tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="model !== 'lead' && !returnAll">
  <div *ngIf="loading" class="flex justify-content-center"><p-progressSpinner></p-progressSpinner></div>
  <p-accordion [(activeIndex)]="activeAccordionIndex">
    <p-accordionTab *ngFor="let group of groupedData" [header]="group.relationName">
      <p-table #dt2 [value]="group.items" styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr fp-email-logs-header></tr>
        </ng-template>
        <ng-template pTemplate="body" let-emailLog>
          <tr
            fp-email-logs-body
            [emailLog]="emailLog"
            (emailLogChange)="onEmailLogChange($event)"
            [ngClass]="{ 'email-disabled': emailLog.dont_send || emailLog.sent_at }"
          ></tr>
        </ng-template>
      </p-table>
    </p-accordionTab>
  </p-accordion>
</div>
