import { Action } from '@ngrx/store';
import { User } from 'src/app/shared/types/user.types';

export const USERS_LOAD = '[USER] Load users';
export const USERS_LOAD_SUCCESS = '[USER] Load users successful';
export const USERS_LOAD_ERROR = '[USER] Load users failed';

export class UsersLoad implements Action {
  readonly type = USERS_LOAD;
}

export class UsersLoadSuccess implements Action {
  readonly type = USERS_LOAD_SUCCESS;
  constructor(public payload: User[]) {}
}

export class UsersLoadError implements Action {
  readonly type = USERS_LOAD_ERROR;
}

export type Actions = UsersLoad | UsersLoadSuccess | UsersLoadError;
