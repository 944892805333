import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  EmailLogLoad,
  EmailLogLoadSuccess,
  EmailLogSendSuccess,
  EmailLogSetInitial,
  UpdateEmailLog,
} from 'src/app/core/actions/email-log.actions';
import { AppState } from 'src/app/core/reducers';
import { EmailLog } from 'src/app/shared/types/email-log.types';
import { hasValue, RequireOne } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { EmailLogService } from '../../services/email-log.service';
import { UtilsService } from '../../services/utils.service';
import { HttpParams } from '@angular/common/http';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'fp-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.scss'],
})
export class EmailLogsComponent implements OnInit, OnChanges, OnDestroy {
  environment = environment;
  @Input() returnAll = true;
  @Input() submissionId: string;
  @Input() model: string;
  emailLogs: EmailLog[];
  loading: boolean;
  totalRecords = 0;
  logsSubscription: Subscription;
  groupedData = [];
  activeAccordionIndex: number | number[]; // Keeps track of the active accordion tab
  constructor(
    private notificationService: NotificationService,
    private store: Store<AppState>,
    public utilService: UtilsService,
    public emailLogService: EmailLogService
  ) {}

  loadEmailLogs(event: LazyLoadEvent) {
    if (this.returnAll) {
      this.loading = true;
      const params = this.utilService.paramsFromEvent(event);
      this.logsSubscription = this.emailLogService.getEmailLogs(params).subscribe(res => {
        const results = res['results'] as EmailLog[];
        this.totalRecords = res['count'];
        this.loading = false;
        this.store.dispatch(new EmailLogLoadSuccess(results));
      });
    } else {
      this.loading = true;
      const params = this.utilService.paramsFromEvent(event);
      if (this.submissionId)
        this.logsSubscription = this.emailLogService.getEmailLogsForSubmission(params, this.submissionId).subscribe(res => {
          const results = res['results'] as EmailLog[];
          this.totalRecords = res['count'];
          this.loading = false;
          this.store.dispatch(new EmailLogLoadSuccess(results));
        });
    }
  }

  loadEmailsLogsNotPaginated() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('offset', 0);
    params = params.append('limit', 2000);
    if (this.submissionId)
      this.logsSubscription = this.emailLogService.getEmailLogsForSubmission(params, this.submissionId).subscribe(res => {
        const results = res['results'] as EmailLog[];
        this.totalRecords = res['count'];
        this.groupEmailsDataByRelation(results);
        this.loading = false;
        this.store.dispatch(new EmailLogLoadSuccess(results));
      });
  }

  ngOnInit() {
    this.store.dispatch(new EmailLogSetInitial());
    if (this.model !== 'lead' && !this.returnAll) {
      this.loadEmailsLogsNotPaginated();
    }
    this.store
      .select(state => state.emailLogs)
      .pipe(filter(hasValue))
      .subscribe(emailLogs => {
        this.emailLogs = cloneDeep(emailLogs);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof this.submissionId !== 'undefined') {
      this.store.dispatch(new EmailLogLoad(this.submissionId, this.returnAll));
    }
  }

  clear(table: Table) {
    table.clear();
  }

  ngOnDestroy(): void {
    if (this.logsSubscription) {
      this.logsSubscription.unsubscribe();
    }
  }

  /**
   * This function takes email logs from the BE response and processes them as follows:
   * 1. Groups them into relations for which they belong (if none - there they are taken as general emails)
   * 2. Sorts these relation groups so that the newest ones are on the top of the list (general are taken as the newest)
   */
  groupEmailsDataByRelation(data: EmailLog[]) {
    const grouped = data.reduce((groupedEmails: object, item: EmailLog) => {
      let relation = 'General emails';
      const relationText = 'Emails related to ';
      let created_at = item.relation.created_at ? new Date(item.relation.created_at) : new Date();

      if (this.model !== 'mentee') {
        if (item.relation.mentee_name) {
          relation = relationText + item.relation.mentee_name;
        }
      } else {
        if (item.relation.mentor_name) {
          relation = relationText + item.relation.mentor_name;
        } else if (item.relation.coach_name) {
          relation = relationText + item.relation.coach_name;
        }
      }
      if (!groupedEmails[relation]) {
        groupedEmails[relation] = {
          relationName: relation,
          created_at: created_at,
          items: [],
        };
      }
      groupedEmails[relation].items.push(item);
      return groupedEmails;
    }, {});

    const groupedEmailsArray: any[] = Object.values(grouped);
    groupedEmailsArray.sort((relation1, relation2) => relation2.created_at.getTime() - relation1.created_at.getTime());

    this.groupedData = groupedEmailsArray;
  }

  /**
   * Handles changes to the email log based on the specified change type.
   *
   * @param event - An object containing the email log and the type of change.
   */
  onEmailLogChange(event: { emailLog: EmailLog; changeType: string }) {
    const { emailLog, changeType } = event;

    switch (changeType) {
      case 'dontSend':
        this.handleDontSendChange(emailLog);
        break;
      case 'sentAt':
        this.handleSentAtChange(emailLog);
        break;
      default:
        console.warn(`Unknown change type: ${changeType}`);
    }
  }

  /**
   * Handles the 'dontSend' change type by toggling the 'dont_send' property
   * of the email log and updating the store and grouped data accordingly.
   *
   * @param emailLog - The email log that has changed.
   */
  private handleDontSendChange(emailLog: EmailLog) {
    const newEmailLog: RequireOne<EmailLog, 'id'> = { id: emailLog.id, dont_send: !emailLog.dont_send };
    this.store.dispatch(new UpdateEmailLog(newEmailLog));
    if (this.model !== 'lead' && !this.returnAll) {
      this.updateGroupedData(emailLog.id, { dont_send: newEmailLog.dont_send });
    }
  }

  /**
   * Handles the 'sentAt' change type by sending the email and updating the
   * store and grouped data upon success.
   *
   * @param emailLog - The email log that has changed.
   */
  private handleSentAtChange(emailLog: EmailLog) {
    this.emailLogService.sendEmail(emailLog.id).subscribe({
      next: res => {
        this.store.dispatch(new EmailLogSendSuccess(res['email_log']));
        if (this.model !== 'lead' && !this.returnAll) {
          this.updateGroupedData(emailLog.id, { sent_at: new Date().toISOString() });
        }
      },
      error: err => {
        this.notificationService.openSnackBar('error', err.error);
      },
    });
  }

  /**
   * Updates the grouped data to reflect changes to a specific email log.
   *
   * @param id - The ID of the email log that has changed.
   * @param changes - An object containing the changes to apply to the email log.
   */
  private updateGroupedData(id: string, changes: Partial<EmailLog>) {
    this.groupedData = this.groupedData.map(group => ({
      ...group,
      items: group.items.map(item => (item.id === id ? { ...item, ...changes } : item)),
    }));
  }
}
