import { Action } from '@ngrx/store';
import { Programme } from 'src/app/shared/types/programme.types';

export const PROGRAMMES_LOAD = '[PROGRAMME] Load programmes';
export const PROGRAMMES_LOAD_SUCCESS = '[PROGRAMME] Load programmes successful';
export const PROGRAMMES_LOAD_ERROR = '[PROGRAMME] Load programmes failed';

export class ProgrammesLoad implements Action {
  readonly type = PROGRAMMES_LOAD;
}

export class ProgrammesLoadSuccess implements Action {
  readonly type = PROGRAMMES_LOAD_SUCCESS;
  constructor(public payload: Programme[]) {}
}

export class ProgrammesLoadError implements Action {
  readonly type = PROGRAMMES_LOAD_ERROR;
}

export type Actions = ProgrammesLoad | ProgrammesLoadSuccess | ProgrammesLoadError;
